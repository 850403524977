import { LocationApi, type LocationControllerFindOneFrontCenters200Response } from '@repo/api-client';
import type {
  ApiGouvAutocompleteResult} from '../components/store-locator/types';
import {
  type LocationTreeFrontData,
  type RawStoreLocatorSearchQuery,
  type StoreLocatorPageProps,
  type StoreLocatorSearchQuery,
} from '../components/store-locator/types';
import { getHttpClient } from './http-client';
import { isNextNotFoundError, parseNumberQueryParam, parseStringQueryParam } from './next-utils';
import { DEFAULT_DISTANCE, type StoreLocatorQuery, type StoreLocatorUrlParams } from './stores/use-store-locator';
import { type NextPageProps } from '@web/types';
import { notFound, redirect } from 'next/navigation';

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace StoreLocatorUtils {
  export const baseUrl = `/centres-de-selfstockage/`;

  export function buildUrl(
    locationPath?: string,
    params?: StoreLocatorQuery,
    urlQuery?: StoreLocatorUrlParams,
  ) {
    const path =
      '/' +
      [
        baseUrl.replace(/\//g, ''),
        locationPath?.split('/').filter(Boolean).join('/') ?? 'france',
      ].join('/');

    if (!params && !urlQuery) return path;

    const urlQueryEntries = Object.entries({
      search: params?.search,
      gpsLat: params?.gpsLat?.toString(),
      gpsLng: params?.gpsLng?.toString(),
      distance: params?.distance?.toString(),
      inRangeOnly: undefined,
      focus: urlQuery?.focus,
      scrollToMap: urlQuery?.scrollToMap === true ? '' : undefined,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    }).filter(([key, value]) => value !== undefined) as [string, string][];

    return `${path}?${new URLSearchParams( Object.fromEntries(urlQueryEntries) )}`;
  }

  export async function fetchStoreLocatorSearchResults(
    locationSlug: string,
    query?: StoreLocatorQuery
  ) {
    const locationClient = new LocationApi(undefined, '', getHttpClient());

    const {data} = await locationClient.locationControllerFindOneFrontCenters(
      locationSlug,
      query?.gpsLat,
      query?.gpsLng,
      query?.distance,
      false, // in range only
      query?.search,
    ).then((res) => res.data as LocationControllerFindOneFrontCenters200Response);

    const {centers, ...location} = data.location;

    return {
      query: data.query satisfies StoreLocatorQuery,
      location,
      centers, 
    }
  }

  export const fetchStoreLocatorPageProps = async (
    context: NextPageProps<{ country?: string, region?: string, department?: string }>
  ) => {
    const locationClient = new LocationApi(undefined, '', getHttpClient());

    // Path params
    const country = parseStringQueryParam(context?.params?.country);
    const region = parseStringQueryParam(context?.params?.region);
    const department = parseStringQueryParam(context?.params?.department);
    const locationSlug = department ?? region ?? country ?? 'france';

    // Query
    const query = {
      search : parseStringQueryParam(context?.searchParams?.search),
      gpsLat : parseNumberQueryParam(context?.searchParams?.gpsLat),
      gpsLng : parseNumberQueryParam(context?.searchParams?.gpsLng),
      distance : parseNumberQueryParam(context?.searchParams?.distance) ?? DEFAULT_DISTANCE,
    } satisfies StoreLocatorQuery;

    try {
      const [locationTree, result] = await Promise.all([
        // Location tree
        locationClient.locationControllerTree(true).then((res) => res.data.data),
        // Location
        fetchStoreLocatorSearchResults(locationSlug, query)
      ]);

      // If user is making a geocoding search that is successful
      if (query.search && (!query.gpsLat || !query.gpsLng) && (result.query.gpsLat && result.query.gpsLng) ) {
        return redirect(buildUrl('france', result.query));
      }


      return {
        query: result.query,
        location: result.location,
        centers: result.centers,
        locationTree: locationTree as LocationTreeFrontData,
      } satisfies StoreLocatorPageProps;
    } catch (e) {
      // redirect() throw an error, if its a redirection we rethrow it
      if (isNextNotFoundError(e)) throw e;
      // console.log(e);
      return notFound();
    }
  };

  /**
   * Scan the document to figure it out which PAC container is related to an autocomplete instance
   *
   * @param foundCallback the callback that will be called if the pac container is found
   * @returns
   */
  export function findPacContainer(
    foundCallback: (pacContainer: HTMLDivElement) => void
  ): void {
    if (typeof window === 'undefined' || typeof document === 'undefined')
      return;

    // Observer option
    const obsOptions = {
      attributeFilter: ['style'],
      attributeOldValue: true,
    } satisfies MutationObserverInit;

    // Observer that will listen for PAC container mutations
    const observer = new MutationObserver(
      (mutationList: MutationRecord[], observer: MutationObserver) => {
        // Filter mutations to find the one that has a pac container visible
        const resultMutation = mutationList.find((mutation) => {
          const el = mutation.target as HTMLDivElement;
          return (
            window.getComputedStyle(el).getPropertyValue('display') !== 'none'
          );
        });

        // Exit if we didn't find it
        if (!resultMutation) return;

        // Execute the user call back if successful and stop observing
        foundCallback(resultMutation.target as HTMLDivElement);
        observer.disconnect();
      }
    );

    // observe every pac container on the page
    document.querySelectorAll('body > .pac-container').forEach((el) => {
      observer.observe(el, obsOptions);
    });
  }

  export function getSeoLocationNamePrefix(index: number) {
    const keywords = [
      'self stockage',
      'garde-meuble',
      'archivage physique',
      'box à louer',
    ];

    return keywords[index % keywords.length];
  }

  export function isFullQuery(query: StoreLocatorSearchQuery) : boolean {
    return query.distance !== undefined 
      && query.gpsLat !== undefined
      && query.gpsLng !== undefined
      && query.search !== undefined;
  }

  export function parseQuery(rawQuery: RawStoreLocatorSearchQuery) : StoreLocatorSearchQuery {
    return {
      gpsLat: rawQuery.gpsLat ? parseFloat(rawQuery.gpsLat) : undefined,
      gpsLng: rawQuery.gpsLng ? parseFloat(rawQuery.gpsLng) : undefined,
      distance: rawQuery.distance ? parseInt(rawQuery.distance) : undefined,
      search: rawQuery.search,
      focus: rawQuery.focus,
      scrollToMap: rawQuery.scrollToMap !== undefined
    };
  }

  export function formatApiGouvResult(result?: ApiGouvAutocompleteResult | null) {
    if (!result) return { label: '', details: '' };

    return {
      label: `${result.properties.postcode.substring(0, 2)} - ${result.properties.name}`,
      details: `${result.properties.postcode}, ${result.properties.context.substring(4)}`
    }
  }
}
